import ConfirmModal from "components/Common/ConfirmModal";
import Breadcrumbs from "components/Common/Breadcrumb";
import {useEffect, useState} from "react";
import Api from 'api/products'

import { Button, Card, CardBody, Col, Label, Modal, ModalHeader, Row, Spinner, UncontrolledTooltip } from "reactstrap"
import Add from "./Add";
import CustomPagination from "../../components/CustomPagination";
import Can from "../../components/Common/Can";
import { Controller, useForm } from "react-hook-form"
import Select from "react-select"
import FlatPicker from "react-flatpickr"
import Products from "../../api/products";
import ProductTypes from "../../api/product-types";

const Branches = () => {
    document.title = 'Məhsullar'
    const [confirmModal, setConfirmModal] = useState(false)
    const [form, setForm] = useState({})
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [isFetching, setIsFetching] = useState(true)
    const [products, setProducts] = useState([])
    const [productTypes, setProductTypes] = useState([])
    const {control, errors, reset, getValues, watch, handleSubmit} = useForm()

    const deleteData = async () => {
        await Api.delete(confirmModal)
        fetchData()
    }

    const fetchProducts = async () => {
        const { data } = await Products.getSelect()
        console.log("fetchProducts", data)
        setProducts(data)
    }

    const fetchProductTypes = async () => {
        const { data } = await ProductTypes.getSelect()
        console.log("fetchProductTypes", data)
        setProductTypes(data)
    }

    const fetchFilterData = async () => {
        await Promise.all([fetchProducts(), fetchProductTypes()])
    }

    const productId = watch("productId")
    const productTypeId = watch("productTypeId")

    const fetchData = async (showLoader = true, p = null) => {
        setIsFetching(showLoader)

        // Make API call
        const data = await Api.get(
            {
                page: p || page,
                product_id: productId?.value,
                product_type_id: productTypeId?.value
            }
        )
        setData(data?.data?.products)
        setTotal(data?.meta?.total)
        setIsFetching(false)
    }

    const filter = async () => {
        fetchData(true, 1)
    }

    useEffect(() => {
        fetchFilterData()
    }, [])

    useEffect(() => {
        fetchData(true, 1)
        setPage(1)
    }, [productId, productTypeId])

    useEffect(() => {
        fetchData()
    }, [page])

    return (
        <div className="page-content">
            <ConfirmModal active={confirmModal} setActive={setConfirmModal} callback={deleteData}/>
            <Modal className="modal-dialog-centered" isOpen={form?.status}
                   toggle={() => setForm({})}>
                <ModalHeader
                    toggle={() => setForm({})}>{form?.data ? 'Düzəliş et' : 'Əlavə et'}</ModalHeader>
                <Add fetchData={fetchData} form={form} setForm={setForm}/>
            </Modal>
            <div className="container-fluid">
                <Breadcrumbs breadcrumbItem={`MƏHSULLAR (${total})`}/>
                <Row>
                    <Col sm={12}>
                        <div className="d-flex flex-column gap-2">
                            <Card>
                                <CardBody>
                                    <form onSubmit={handleSubmit(filter)}>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <div className="mb-3">
                                                    <Label for="productId">Məhsul</Label>
                                                    <Controller name="productId"
                                                                control={control}
                                                                render={({ field: { value, onChange } }) => (
                                                                    <Select
                                                                        isClearable
                                                                        options={products}
                                                                        placeholder=""
                                                                        className={`w-100 ${errors?.productId && "is-invalid"}`}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        name="productId"
                                                                        id="productId" />
                                                                )} />
                                                </div>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <div className="mb-3">
                                                    <Label for="productTypeId">Məhsul növü</Label>
                                                    <Controller name="productTypeId"
                                                                control={control}
                                                                render={({ field: { value, onChange } }) => (
                                                                    <Select
                                                                        isClearable
                                                                        options={productTypes}
                                                                        placeholder=""
                                                                        className={`w-100 ${errors?.productTypeId && "is-invalid"}`}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        name="productTypeId"
                                                                        id="productTypeId" />
                                                                )} />
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className="d-flex justify-content-end">
                                                    <Button id="reset-btn" outline color="primary" onClick={() => {
                                                        reset({productId: null, productTypeId: null})
                                                        setPage(1)
                                                        fetchData(true, 1)
                                                    }}>
                                                        <i className="bx bx-rotate-right" />
                                                    </Button>
                                                    <UncontrolledTooltip placement="bottom" target="reset-btn">
                                                        Sıfırla
                                                    </UncontrolledTooltip>
                                                    <Button type="submit" color="primary">
                                                        Axtar
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                            <Card>
                                {isFetching ? (
                                    <div className="d-flex justify-content-center p-5">
                                        <Spinner color="primary" size="lg" />
                                    </div>
                                ) : (
                                    <CardBody>
                                        <Can action="product_add">
                                            <Button
                                                onClick={() => setForm({ status: true })}
                                                type="button"
                                                color="success"
                                                className="btn-label mb-3"
                                            >
                                                <i className="bx bx-plus label-icon" /> Əlavə et
                                            </Button>
                                        </Can>
                                        <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th>№</th>
                                                    <th>Ad</th>
                                                    <th>Qiymət</th>
                                                    <th>Növ</th>
                                                    <th />
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {data?.map((item, index) => (
                                                    <tr key={item.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.price}</td>
                                                        <td>{item?.type_name}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <Can action="product_edit">
                                                                    <Button color="success"
                                                                            id={`edit-${item.id}`}
                                                                            onClick={() => setForm({
                                                                                status: true,
                                                                                data: item
                                                                            })}>
                                                                        <i className="bx bx-pencil" />
                                                                    </Button>
                                                                    <UncontrolledTooltip target={`edit-${item.id}`}
                                                                                         placement="bottom">
                                                                        Düzəliş et
                                                                    </UncontrolledTooltip>
                                                                </Can>
                                                                <Can action="product_delete">
                                                                    <Button color="danger"
                                                                            id={`delete-${item.id}`}
                                                                            onClick={() => setConfirmModal(item.id)}>
                                                                        <i className="bx bx-trash" />
                                                                    </Button>
                                                                    <UncontrolledTooltip target={`delete-${item.id}`}
                                                                                         placement="bottom">
                                                                        Sil
                                                                    </UncontrolledTooltip>
                                                                </Can>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <CustomPagination total={total} setPage={setPage} page={page} />
                                    </CardBody>
                                )}
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Branches
