import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Badge, Button, Col, Input, Label, ModalBody, ModalFooter, Row, Spinner, UncontrolledTooltip } from "reactstrap"
import Select from "react-select"
import FormHelper from "../../helpers/form"
import FlatPicker from "react-flatpickr"
import Employees from "../../api/employees"
import Products from "../../api/products"
import Api from "api/purchase"
import moment from "moment/moment"
import { indexOf } from "lodash"

const ModalForm = ({ modalData, types, suppliers, setForm, fetchData }) => {

	const [loader, setLoader] = useState(false)
	const [workers, setWorkers] = useState([])
	const [products, setProducts] = useState([])
	const [productArr, setProductArr] = useState([])
	const [validationErrors, setValidationErrors] = useState({})
	const [disableEditbutton, setDisableEditbutton] = useState(true)
	const [disableAddbutton, setDisableAddbutton] = useState(false)

	const transactionForm = useForm({
		defaultValues: {
			supplier_id: "",
			worker_id: "",
			date: "",
			note: ""
		}
	})

	const productForm = useForm({
		defaultValues: {
			index: "",
			product_id: "",
			quantity: "",
			price_first: "",
			percent: "",
			price: "",
			code: "",
			brand: "",
			is_legal: ""
		}
	})

	// index of editing product
	const index = productForm.watch("index")

	const fetchWorkers = async () => {
		const { data } = await Employees.getSelect()
		console.log("workers - ", data)
		setWorkers(data)
	}

	const fetchProducts = async () => {
		const { data } = await Products.getSelect()
		console.log("products - ", data)
		setProducts(data)
	}

	const fetchModalData = async () => {
		await Promise.all([fetchWorkers(), fetchProducts()])
	}

	useEffect(() => {
		fetchModalData()
	}, [])

	useEffect(() => {
		if (typeof index === "number" && index > 0 ) {
			setDisableEditbutton(false)
			setDisableAddbutton(true)
		} else if (typeof index === "string") {
			setDisableEditbutton(true)
			setDisableAddbutton(false)
		}

	}, [productForm.formState.isDirty])

	useEffect(() => {

		if (modalData) {
			const { status, data } = modalData
			if (workers?.length) {
				console.log("modalData: ", data)
				if (status && data) {
					fillTransactionForm(data)
				}
			}
			if (products?.length && data?.products?.length) {
				setProductArr(data?.products)
			}
		}

	}, [modalData, workers, products]) // also fire when workers and products fetched

	function fillTransactionForm(data) {

		const { setValue } = transactionForm
		const sup = suppliers?.find(s => s.value === data?.supplier_id)
		const wor = workers?.find(w => w.value === data?.worker_id)
		if (sup)
			setValue("supplier_id", sup)
		if (wor)
			setValue("worker_id", wor)
		if (data?.date)
			setValue("date", data?.date)
		if (data?.note)
			setValue("note", data?.note)
	}

	function calculateFinalPrice(price) {
		const percent = productForm.watch("percent")
		console.log("price_first ->", price)
		console.log("percent ->", percent)
		productForm.setValue("price", Number(((price * Number(percent || 0)) / 100)) + Number(price))
	}

	function calculateFinalPercent(percent) {
		const price_first = productForm.watch("price_first")
		console.log("price_first ->", price_first)
		console.log("percent ->", percent)
		productForm.setValue("price", Number(((price_first * Number(percent || 0)) / 100)) + Number(price_first))
	}

	function addProduct() {
		// Add new product to arr and clear form fields
		const { formState, getValues, reset } = productForm
		if (formState.isValid) {
			const data = getValues()
			console.log("edit: ", data)
			const { product_id, is_legal, quantity, brand, code, percent, price, price_first } = data

			const new_product = {
				product_id: product_id?.value,
				product_name: product_id.label,
				is_legal: is_legal?.value,
				brand,
				code
			}

			// quantity
			new_product.quantity = typeof quantity !== "number" ? parseInt(quantity) : quantity
			// percent
			new_product.percent = typeof percent !== "number" ? parseInt(percent) : percent
			// price
			new_product.price = typeof price !== "number" ? parseFloat(price) : price
			// price_first
			new_product.price_first = typeof price_first !== "number" ? parseFloat(price_first) : price_first

			// add new product to productArr state
			setProductArr(prevState => [...prevState, new_product])

			reset()
			setDisableAddbutton(true) // disable add button
		}

	}

	function saveEdit() {
		// validate form and save edited data
		const { formState, getValues, reset } = productForm
		if (formState.isValid) {
			const data = getValues()
			console.log("edit: ", data)
			const { product_id, is_legal, quantity, brand, code, percent, price, price_first } = data
			// const actual_product = productArr.find(pr => pr.id === id)
			const actual_product = productArr[index]
			if (!actual_product)
				return
			const new_product = {
				...actual_product,
				product_id: product_id?.value,
				product_name: product_id.label,
				is_legal: is_legal?.value,
				brand,
				code
			}
			// quantity
			new_product.quantity = typeof quantity !== "number" ? parseInt(quantity) : quantity
			// percent
			new_product.percent = typeof percent !== "number" ? parseInt(percent) : percent
			// price
			new_product.price = typeof price !== "number" ? parseFloat(price) : price
			// price_first
			new_product.price_first = typeof price_first !== "number" ? parseFloat(price_first) : price_first

			// update productArr state
			// setProductArr(prevState => prevState.map((pr, index) => index === id ? new_product : pr))
			setProductArr(prevState => prevState.map(pr => pr.id === actual_product.id ? new_product : pr))

			reset()

			setDisableEditbutton(true)
		}
	}

	function editProductRow(product) {
		if (product && productArr?.length) {
			const { setValue } = productForm
			const product_id = products.find(p => p.value === product.product_id)
			const is_legal = types.find(t => t.value === product.is_legal)

			if (product_id)
				setValue("product_id", product_id)
			if (product.quantity)
				setValue("quantity", product.quantity)
			if (product.price_first)
				setValue("price_first", product.price_first)
			if (typeof product.percent === "number")
				setValue("percent", product.percent)
			if (product.price)
				setValue("price", product.price)
			if (product.code)
				setValue("code", product.code)
			if (product.brand)
				setValue("brand", product.brand)
			if (product.is_legal)
				setValue("is_legal", is_legal)
			console.log("index:", productArr.indexOf(product))
			// set unique (I guess ) id value to hidden input to figure out which product is editing in saveEdit function.
			setValue("index", productArr.indexOf(product))
			setDisableEditbutton(false)
			setDisableAddbutton(true)
		}
	}

	function deleteProductRow(index) {
		setProductArr(prevState => prevState.filter((pr, _index) => _index != index))
	}

	async function submitModal(data) {
		console.log(data)
		console.log("products: ", productArr)
		try {
			const prepareData = {
				id: modalData?.data?.id,
				worker_id: data?.worker_id?.value,
				supplier_id: data?.supplier_id?.value,
				date: moment(data?.date)?.format("YYYY-MM-DD"),
				note: data?.note,
				products: productArr.map(pr => ({
					...pr,
					// TODO this properties have to be changed to string at appropriate places (addProduct and saveEdit)
					product_id: pr?.product_id,
					is_legal: pr?.is_legal
				}))
			}
			console.log(prepareData)

			await Api[prepareData.id ? "update" : "add"](prepareData)
			fetchData()
			setForm({})
		} catch (error) {
			console.log("error: ", error)
			console.log("transactionForm: ", transactionForm)
			// Check if error response status is 422 (validation error)
			if (error.response && error.response.status === 422) {
				// Extract validation errors from the error response
				const validationErrors = error.response.data.errors
				// Show validation errors to the user
				console.log(validationErrors)
				// Example: Update state to display validation errors to the user
				setValidationErrors(validationErrors)
			} else {
				// Handle other types of errors (e.g., server errors)
				console.error("An error occurred:", error)
				// Example: Show a generic error message to the user
				alert("An error occurred. Please try again later.")
			}
		}
		// const prepareData = {
		//     id: modalData?.data?.id,
		//     worker_id: data?.worker_id?.value,
		//     supplier_id: data?.supplier_id?.value,
		//     date: moment(data?.date)?.format("YYYY-MM-DD"),
		//     note: data?.note,
		//     products: productArr.map(pr => ({
		//         ...pr,
		//         product_id: pr?.product_id?.value ? pr.product_id.value : pr.product_id,
		//         is_legal: pr?.is_legal?.value ? pr.is_legal.value : pr.is_legal,
		//     }))
		// }
		// await Api[prepareData.id ? "update" : "add"](prepareData)
		// fetchData()
		// setForm({})
	}

	return (
		<>
			<ModalBody>
				<Row>
					{/* Transaction Form */}
					<Col sm={12} md={5}>
						<form>
							<div className="mb-3">
								<Label for="supplier_id">Təchizatçı</Label>
								<Controller
									name="supplier_id"
									control={transactionForm.control}
									render={({ field }) => (
										<Select
											options={suppliers} {...field}
											className={`w-100 ${transactionForm.formState.errors?.supplier_id && "is-invalid"}`}
										/>
									)}
								/>
								{FormHelper.generateFormFeedback(transactionForm.formState.errors, "supplier_id")}
							</div>
							<div className="mb-3">
								<Label for="worker_id">İşçi</Label>
								<Controller
									name="worker_id"
									control={transactionForm.control}
									render={({ field }) => (
										<Select
											options={workers} {...field}
											className={`w-100 ${transactionForm.formState.errors?.worker_id && "is-invalid"}`}
										/>
									)}
								/>
								{FormHelper.generateFormFeedback(transactionForm.formState.errors, "worker_id")}

							</div>
							<div className="mb-3">
								<Label for="date">Tarix</Label>
								<Controller
									name="date"
									control={transactionForm.control}
									render={({ field }) => (
										<FlatPicker
											{...field}
											value={field.value}
											onChange={([date]) => {
												field.onChange(date)
											}}
											className={`form-control w-100 ${transactionForm.formState.errors?.date && "is-invalid"}`}
										/>
									)}
								/>
								{validationErrors.date && <span className="error">{validationErrors.date[0]}</span>}
							</div>
							<div className="mb-3">
								<Label for="note">Qeyd</Label>
								<Controller
									name="note"
									control={transactionForm.control}
									render={({ field }) => (
										<Input
											type="textarea"
											rows={5}
											{...field}
											className={`w-100 ${transactionForm.formState.errors?.note && "is-invalid"}`}
										/>
									)}
								/>
								{FormHelper.generateFormFeedback(transactionForm.formState.errors, "note")}
							</div>
						</form>
					</Col>


					{/* Product Form */}

					<Col sm={12} md={7}>
						<form onSubmit={productForm.handleSubmit(submitModal)}>
							<input type="text" hidden {...productForm.register("index", { required: false })} />
							<div className="mb-3">
								<Label for="product_id">Məhsul</Label>
								<Controller
									// product name gelmir buna baxmaq lazimdi
									name={"product_id"}
									control={productForm.control}
									rules={{ required: "Bu sahə doldurulmalıdır" }}
									render={({ field }) => (
										<Select
											options={products}
											{...field}
											className={`w-100 ${productForm.formState.errors?.product_id && "is-invalid"}`}
										/>
									)}
								/>
								{FormHelper.generateFormFeedback(productForm.formState.errors, "product_id")}
							</div>
							<div className="mb-3">
								<Label for="quantity">Sayı</Label>
								<Controller
									name={`quantity`}
									rules={{ required: "Bu sahə doldurulmalıdır" }}
									control={productForm.control}
									render={({ field }) => (
										<Input
											type="number"
											{...field}
											className={`w-100 ${productForm.formState.errors?.quantity && "is-invalid"}`}
										/>
									)}
								/>
								{/*{FormHelper.generateFormFeedback(productForm.formState.errors, "quantity")}*/}
								{/* Display validation error message for quantity field */}
								{productForm.formState.errors.quantity && (
									<div className="invalid-feedback">
										{productForm.formState.errors.quantity.message} {/* Assuming you have defined custom error messages */}
									</div>
								)}
							</div>
							<div className="mb-3">
								<Label for="price_first">Köhnə qiymət</Label>
								<Controller
									name={`price_first`}
									rules={{ required: "Bu sahə doldurulmalıdır" }}
									control={productForm.control}
									render={({ field }) => (
										<Input
											type="number"
											{...field}
											onChange={(e) => {
												calculateFinalPrice(e.target.value)
												field.onChange(e)
											}}
											className={`w-100 ${productForm.formState.errors?.price_first && "is-invalid"}`}
										/>
									)}
								/>
								{FormHelper.generateFormFeedback(productForm.formState.errors, "price_first")}
							</div>
							<div className="mb-3">
								<Label for="percent">Faiz</Label>
								<Controller
									name={`percent`}
									rules={{ required: "Bu sahə doldurulmalıdır" }}
									control={productForm.control}
									render={({ field }) => (
										<Input
											type="number"
											{...field}
											onChange={(e) => {
												calculateFinalPercent(e.target.value)
												field.onChange(e)
											}}
											className={`w-100 ${productForm.formState.errors?.percent && "is-invalid"}`}
										/>
									)}
								/>
								{FormHelper.generateFormFeedback(productForm.formState.errors, "percent")}
							</div>
							<div className="mb-3">
								<Label for="price">Yekun qiyməti</Label>
								<Controller
									name={`price`}
									rules={{ required: "Bu sahə doldurulmalıdır" }}
									control={productForm.control}
									render={({ field }) => (
										<Input
											disabled
											type="number"
											{...field}
											className={`w-100 ${productForm.formState.errors?.price && "is-invalid"}`}
										/>
									)}
								/>
								{FormHelper.generateFormFeedback(productForm.formState.errors, "price")}
							</div>
							<div className="mb-3">
								<Label for="code">Məhsul kodu</Label>
								<Controller
									name={`code`}
									control={productForm.control}
									render={({ field }) => (
										<Input
											type="text"
											{...field}
											className={`w-100 ${productForm.formState.errors?.code && "is-invalid"}`}
										/>
									)}
								/>
								{FormHelper.generateFormFeedback(productForm.formState.errors, "code")}
							</div>
							<div className="mb-3">
								<Label for="brand">Marka</Label>
								<Controller
									name={`brand`}
									control={productForm.control}
									render={({ field }) => (
										<Input
											type="text"
											{...field}
											className={`w-100 ${productForm.formState.errors?.brand && "is-invalid"}`}
										/>
									)}
								/>
							</div>
							<div className="mb-3">
								<Label for="is_legal">Rəsmi/Q.Rəsmi</Label>
								<Controller
									name={`is_legal`}
									control={productForm.control}
									render={({ field }) => (
										<Select
											options={types}
											{...field}
											className={`w-100 ${productForm.formState.errors?.is_legal && "is-invalid"}`}
										/>
									)}
								/>
							</div>
						</form>
						<Col sm={12} md={12}>
							<Button className="col-5 me-1" onClick={addProduct}
									disabled={!productForm.formState.isValid || disableAddbutton}
									color="primary">
								<i className="bx bx-plus" /> Əlavə et
							</Button>
							<Button className="col-5 mw-1" onClick={saveEdit}
									disabled={!productForm.formState.isValid || disableEditbutton}
									color="primary">
								<i className="bx bx-save" /> Düzəlişi yadda saxla
							</Button>
						</Col>
					</Col>

					{/* Product List */}
					<Col sm={12}>
						{productArr?.length > 0 && (
							<div style={{ maxHeight: "400px", overflow: "auto" }}>
								<div className="table-responsive w-100">
									<table className="table table-bordered w-100">
										<thead>
										<tr>
											<th>Məhsul</th>
											<th>Sayı</th>
											{/*<th>ƏDV(%)</th>*/}
											<th>Köhnə qiyməti</th>
											<th>Faiz</th>
											<th>Yekun qiyməti</th>
											<th>Kod</th>
											<th>Marka</th>
											<th>Rəsmi/Q.Rəsmi</th>
											<th colSpan={2}>Operatorlar</th>
										</tr>
										</thead>
										<tbody>
										{productArr.map((product, index) => (
											<tr key={product.id}>
												<td>{product?.product_id?.label || product?.product_name}</td>
												<td>{product?.quantity}</td>
												{/*<td>{product?.edv}</td>*/}
												<td>{product?.price_first}</td>
												<td>{product?.percent}</td>
												<td>{product?.price}</td>
												<td>{product?.code}</td>
												<td>{product?.brand}</td>
												<td>{product?.is_legal?.label || types?.find(x => x?.value === product?.is_legal)?.label}</td>
												<td>
													<Button size="sm"
															color="warning"
															type="button"
															id={`edit-${product.id}`}
															onClick={() => {
																editProductRow(product)
															}}
													>
														<i className={`bx bx-pencil`} />
													</Button>
													<UncontrolledTooltip
														target={`edit-${product.id}`}
														placement="bottom">
														Düzəliş et
													</UncontrolledTooltip>
												</td>
												<td>
													<Button size="sm"
															color="danger"
															type="button"
															id={`delete-${product.id}`}
															onClick={() => deleteProductRow(index)}>
														<i className={`bx bx-trash`} />
													</Button>
													<UncontrolledTooltip
														target={`delete-${product.id}`}
														placement="bottom">
														Sil
													</UncontrolledTooltip>
												</td>
											</tr>
										))}
										</tbody>
										<tfoot>
										<tr>
											<td />
											<td />
											<td />
											<td><b>Yekun məbləğ</b></td>
											<td>
												<Badge color="danger">
													{productArr.reduce((acc, val) => {
														return Number(acc) + ((Number(val?.price) + Number(val?.edv || 0)) * Number(val?.quantity))
													}, 0)}
												</Badge>
											</td>
											<td />
											<td />
											<td />
											<td colSpan={2} />
										</tr>
										</tfoot>
									</table>
								</div>
							</div>
						)}
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<div className="d-flex justify-content-end gap-1">
					<Button outline type="button" onClick={() => setForm({})}
							color="secondary">Bağla</Button>
					<Button disabled={loader || !productArr?.length} type="submit"
							onClick={transactionForm.handleSubmit(submitModal)} color="primary">
						{loader ? <Spinner color="light" size={"sm"} /> : "Yadda saxla"}
					</Button>
				</div>
			</ModalFooter>
		</>
	)
}

export default ModalForm