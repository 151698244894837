import ConfirmModal from "components/Common/ConfirmModal";
import Breadcrumbs from "components/Common/Breadcrumb";
import {useEffect, useState} from "react";
import Api from 'api/purchase'
import {Button, Card, CardBody, Col, Label, Modal, ModalHeader, Row, Spinner, UncontrolledTooltip} from "reactstrap"
import ModalForm from "./ModalForm";
import CustomPagination from "../../components/CustomPagination";
import Can from "../../components/Common/Can";
import moment from "moment";
import {Controller, useForm} from "react-hook-form"
import Select from "react-select"
import FlatPicker from "react-flatpickr";
import Form from "../../helpers/form";
import Suppliers from "../../api/suppliers";

const Branches = () => {
    document.title = 'Mədaxil'
    const [confirmModal, setConfirmModal] = useState(false)
    const [modalData, setModalData] = useState({})
    const [data, setData] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(500)
    const [isFetching, setIsFetching] = useState(true)
    const {control, errors, reset, getValues, watch, handleSubmit} = useForm()

    const deleteData = async () => {
        await Api.delete(confirmModal)
        await fetchData()
    }

    const fetchSuppliers = async () => {
        const {data} = await Suppliers.getSelect()
        console.log("fetchSuppliers", data)
        setSuppliers(data)
    }

    const fetchFilterData = async () => {
        await Promise.all([fetchSuppliers()])
    }

    const supplierId = watch("supplierId")
    const start_date = watch("start_date")
    const end_date = watch("end_date")

    const types = [
        {
            value: 1,
            label: 'Rəsmi',
        },
        {
            value: 2,
            label: 'Q.Rəsmi'
        }
    ]

    const fetchData = async (showLoader = true, p = null) => {
        setIsFetching(showLoader)

        // Capture supplier_name value
        const supplierNameValue = getValues('supplier_name');

        // Convert modalData date
        const formattedDate = Form.convertFormDate(getValues()?.date);

        // Make API call
        const data = await Api.get(
            {
                page: p || page,
                limit: limit,
                date: formattedDate,
                supplier_id: supplierId?.value,
                start_date: start_date ? Form.convertFormDate(new Date(start_date)) : null,
                end_date: end_date ? Form.convertFormDate(new Date(end_date)) : null,
            }
        )

        setData(data?.data?.transactions)
        console.log('transactions: ', data?.data?.transactions)
        setTotal(data?.meta?.total)
        setIsFetching(false)
    }

    const filter = async () => {
        await fetchData(true, 1)
    }

    useEffect(() => {
        fetchFilterData()
    }, [])

    useEffect(() => {
        fetchData(true, page)
        setPage(1)
    }, [supplierId, start_date, end_date])

    useEffect(() => {
        fetchData(true, page)
    }, [page])

    return (
        <div className="page-content">
            <ConfirmModal active={confirmModal} setActive={setConfirmModal} callback={deleteData}/>
            <Modal size="xl" className="modal-dialog-centered" isOpen={modalData?.status}
                   toggle={() => setModalData({})}>
                <ModalHeader
                    toggle={() => setModalData({})}>{modalData?.data ? 'Düzəliş et' : 'Əlavə et'}</ModalHeader>
                <ModalForm
                    types={types}
                    modalData={modalData}
                    setForm={setModalData}
                    fetchData={fetchData}
                    suppliers={suppliers}/>
            </Modal>
            <div className="container-fluid">
                <Breadcrumbs breadcrumbItem={`MƏDAXİL (${total})`}/>
                <Row>
                    <Col sm={12}>
                        <div className="d-flex flex-column gap-2">
                            <Card>
                                <CardBody>
                                    <form onSubmit={handleSubmit(filter)}>
                                        <Row>
                                            <Col sm={12} md={4}>
                                                <div className="mb-3">
                                                    <Label for="supplierId">Təchizatçı</Label>
                                                    <Controller name="supplierId"
                                                                control={control}
                                                                render={({field: {value, onChange}}) => (
                                                                    <Select
                                                                        isClearable
                                                                        options={suppliers}
                                                                        placeholder=""
                                                                        className={`w-100 ${errors?.supplierId && "is-invalid"}`}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        name="supplierId"
                                                                        id="supplierId"/>
                                                                )}/>
                                                </div>
                                            </Col>
                                            <Col sm={12} md={4}>
                                                <div className="mb-3 w-100">
                                                    <Label for="start_date">Başlama tarixi</Label>
                                                    <Controller name="start_date"
                                                                control={control}
                                                                render={({ field: { value, onChange } }) => (
                                                                    <FlatPicker
                                                                        className="form-control d-block"
                                                                        value={value}
                                                                        onChange={onChange}
                                                                        options={{
                                                                            locale: "az"
                                                                        }}
                                                                    />
                                                                )} />
                                                </div>
                                            </Col>
                                            <Col sm={12} md={4}>
                                                <div className="mb-3 w-100">
                                                    <Label for="end_date">Bitmə tarixi</Label>
                                                    <Controller name="end_date"
                                                                control={control}
                                                                render={({ field: { value, onChange } }) => (
                                                                    <FlatPicker
                                                                        className="form-control d-block"
                                                                        value={value}
                                                                        onChange={onChange}
                                                                        options={{
                                                                            locale: "az"
                                                                        }}
                                                                    />
                                                                )} />
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className="d-flex justify-content-end gap-2">
                                                    <Button id="reset-btn" outline color="primary" onClick={() => {
                                                        reset({supplierId: null})
                                                        setPage(1)
                                                        fetchData(true, 1)
                                                    }}>
                                                        <i className="bx bx-reset"/>
                                                    </Button>
                                                    <UncontrolledTooltip placement="bottom" target="reset-btn">
                                                        Sıfırla
                                                    </UncontrolledTooltip>
                                                    <Button type="submit" color="primary">
                                                        Axtar
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                            <Card>
                                {isFetching ? (
                                    <div className="d-flex justify-content-center p-5">
                                        <Spinner color="primary" size={"sm"}/>
                                    </div>
                                ) : (
                                    <CardBody>
                                        <Can action="purchase_add">
                                            <Button
                                                onClick={() => setModalData({status: true})}
                                                type="button"
                                                color="success"
                                                className="btn-label mb-3"
                                            >
                                                <i className="bx bx-plus label-icon"/> Əlavə et
                                            </Button>
                                        </Can>
                                        <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th>№</th>
                                                    <th>Təhcizatçı</th>
                                                    <th>İşçi</th>
                                                    <th>Ümumi qiymət</th>
                                                    <th className="text-center">Tarix</th>
                                                    <th>Qeyd</th>
                                                    <th className="text-center" colSpan={2}>Operatorlar</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {data.map((item, index) => (
                                                    <tr key={item.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.supplier_name}</td>
                                                        <td>{item.worker_name} {item.worker_surname}</td>
                                                        <td>{item.total_amount}</td>
                                                        <td className="text-center">{item?.date && moment(item.date).format('DD.MM.YYYY')}</td>
                                                        <td>{item.note}</td>
                                                        <td>
                                                            <div className="d-flex justify-content-center gap-2">
                                                                <Can action="purchase_edit">
                                                                    <Button color="success"
                                                                            id={`edit-${item.id}`}
                                                                            onClick={() => setModalData({
                                                                                status: true,
                                                                                data: item
                                                                            })}>
                                                                        <i className="bx bx-pencil"/>
                                                                    </Button>
                                                                    <UncontrolledTooltip target={`edit-${item.id}`}
                                                                                         placement="bottom">
                                                                        Düzəliş et
                                                                    </UncontrolledTooltip>
                                                                </Can>
                                                                <Can action="purchase_delete">
                                                                    <Button color="danger"
                                                                            id={`delete-${item.id}`}
                                                                            onClick={() => setConfirmModal(item.id)}>
                                                                        <i className="bx bx-trash"/>
                                                                    </Button>
                                                                    <UncontrolledTooltip target={`delete-${item.id}`}
                                                                                         placement="bottom">
                                                                        Sil
                                                                    </UncontrolledTooltip>
                                                                </Can>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <CustomPagination total={total} limit={limit} setPage={setPage} page={page}/>
                                    </CardBody>
                                )}
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Branches
